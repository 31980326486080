import React, { useEffect, useRef } from 'react';
import AnimatedNumbers from 'react-animated-numbers';
import { useGetViewPort } from '~/hooks/useGetViewPort';
import { BlackBox, BottomGradientSection, Containter, ContentBox, GraySpan, Layout, MainSpan, Title, TopGradientSection } from './styled';
const MainCompanyGrowth = () => {
    const targetRef = useRef(null);
    const [isMobile] = useGetViewPort();
    const disableScroll = (() => {
        let accumulatedDeltaY = 0;
        return (event) => {
            const blackBox = document.querySelector('.black-box');
            const currentWidth = blackBox.style.width;
            let deltaY;
            if ('deltaY' in event) {
                deltaY = Math.abs(event.deltaY);
            }
            else {
                const touchEvent = event;
                const touch = touchEvent.touches[0] || touchEvent.changedTouches[0];
                const startTouchY = touch.clientY;
                const moveHandler = (e) => {
                    const moveTouch = e.touches[0] || e.changedTouches[0];
                    deltaY = Math.abs(moveTouch.clientY - startTouchY);
                    accumulatedDeltaY += deltaY;
                    const newWidth = `calc(100vw - ${accumulatedDeltaY * (5 / 3)}px)`;
                    if (currentWidth !== newWidth) {
                        blackBox.style.width = newWidth;
                    }
                };
                const endHandler = () => {
                    document.removeEventListener('touchmove', moveHandler);
                    document.removeEventListener('touchend', endHandler);
                };
                document.addEventListener('touchmove', moveHandler);
                document.addEventListener('touchend', endHandler);
                return;
            }
            accumulatedDeltaY += deltaY;
            const newWidth = `calc(100vw - ${accumulatedDeltaY * (5 / 3)}px)`;
            if (currentWidth !== newWidth) {
                blackBox.style.width = newWidth;
            }
        };
    })();
    const enableScroll = () => {
        document.addEventListener('wheel', disableScroll);
        document.addEventListener('touchstart', disableScroll);
    };
    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 1,
        };
        const observer = new IntersectionObserver(([entry]) => {
            var _a, _b;
            const { isIntersecting } = entry;
            const layout = document.querySelector('.container');
            const fadein = document.querySelector('.fade-in');
            const title = document.querySelector('.title');
            if (isIntersecting) {
                enableScroll();
                console.log('offsetHeight:' + ((_a = targetRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight), (_b = targetRef.current) === null || _b === void 0 ? void 0 : _b.scrollHeight);
                title.style.color = '#FFFFFF';
                fadein.style.transition = 'background-color 1s ease';
                fadein.style.animation = 'gradientAnimation 3s ease-in-out forwards';
                layout.style.backgroundColor = '#000000'; // Change to desired background color
            }
        }, options);
        if (targetRef.current) {
            observer.observe(targetRef.current);
        }
        return () => {
            observer.disconnect();
        };
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Layout, { className: "layout" },
            React.createElement(TopGradientSection, { className: "fade-in", ref: targetRef, style: {
                    background: 'linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #000000 63.6%)',
                } }),
            React.createElement("img", { alt: "graph_3", src: "/img/growth/graph_3.svg", style: {
                    position: 'absolute',
                    width: isMobile ? '100vw' : '106vw',
                    height: isMobile ? '72vh' : '100vh',
                } }),
            React.createElement("img", { alt: "graph_2", src: "/img/growth/graph_2.svg", style: {
                    position: 'absolute',
                    width: isMobile ? '100vw' : '135vw',
                    height: isMobile ? '76vh' : '100vh',
                } }),
            React.createElement("img", { alt: "graph_1", src: "/img/growth/graph_1.svg", style: {
                    position: 'absolute',
                    width: isMobile ? '100vw' : '140vw',
                    height: isMobile ? '76vh' : '100vh',
                } }),
            React.createElement(BlackBox, { className: "black-box" }),
            React.createElement(Containter, { className: "container" },
                React.createElement("div", { style: {
                        display: 'flex',
                    } },
                    isMobile ? (React.createElement(Title, { className: "title" },
                        "\uADF8\uB9B0\uB9AC\uBCF8\uC740",
                        React.createElement("br", null),
                        React.createElement("span", null, "\uC131\uC7A5"),
                        "\uD558\uACE0 \uC788\uC2B5\uB2C8\uB2E4.")) : (React.createElement(Title, { className: "title" },
                        "\uADF8\uB9B0\uB9AC\uBCF8\uC740",
                        React.createElement("br", null),
                        React.createElement("span", null, "\uBAA9\uD45C"),
                        "\uB97C \uAC00\uC9C0\uACE0 \uC6C0\uC9C1\uC785\uB2C8\uB2E4.")),
                    React.createElement("span", { style: {
                            color: '#B5B5B5',
                            letterSpacing: '-0.02em',
                            fontSize: '16px',
                            lineHeight: '137%',
                            fontWeight: 500,
                            display: 'flex',
                            margin: 'auto 0 0 auto',
                        } }, "2025\uB144 3\uC6D4 \uAE30\uC900")),
                React.createElement(ContentBox, null,
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                        } },
                        React.createElement(GraySpan, null, "\uC870\uD68C\uB41C \uB193\uCE5C\uBCF4\uD5D8\uAE08"),
                        React.createElement(MainSpan, null,
                            React.createElement(AnimatedNumbers, { animateToNumber: 1452737365803, configs: [
                                    { mass: 1, tension: 220, friction: 100 },
                                    { mass: 1, tension: 180, friction: 130 },
                                    { mass: 1, tension: 280, friction: 90 },
                                    { mass: 1, tension: 180, friction: 135 },
                                    { mass: 1, tension: 260, friction: 100 },
                                    { mass: 1, tension: 210, friction: 180 },
                                ], includeComma: true, locale: "en-US" }),
                            "\uC6D0")),
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                        } },
                        React.createElement(GraySpan, null, "\uCC3E\uC544\uB4DC\uB9B0 \uBCF4\uD5D8\uAE08 "),
                        React.createElement(MainSpan, null,
                            React.createElement(AnimatedNumbers, { animateToNumber: 18237162161, configs: [
                                    { mass: 1, tension: 220, friction: 100 },
                                    { mass: 1, tension: 180, friction: 130 },
                                    { mass: 1, tension: 280, friction: 90 },
                                    { mass: 1, tension: 180, friction: 135 },
                                    { mass: 1, tension: 260, friction: 100 },
                                    { mass: 1, tension: 210, friction: 180 },
                                ], includeComma: true, locale: "en-US" }),
                            "\uC6D0")),
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                        } },
                        React.createElement(GraySpan, null, "1\uC778 \uD3C9\uADE0 \uD658\uAE09\uC561"),
                        React.createElement(MainSpan, null,
                            React.createElement(AnimatedNumbers, { animateToNumber: 188031, configs: [
                                    { mass: 1, tension: 220, friction: 100 },
                                    { mass: 1, tension: 180, friction: 130 },
                                    { mass: 1, tension: 280, friction: 90 },
                                    { mass: 1, tension: 180, friction: 135 },
                                    { mass: 1, tension: 260, friction: 100 },
                                    { mass: 1, tension: 210, friction: 180 },
                                ], includeComma: true, locale: "en-US" }),
                            ' ',
                            "\uC6D0")),
                    React.createElement("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                        } },
                        React.createElement(GraySpan, null, "\uD68C\uC6D0\uC218"),
                        React.createElement(MainSpan, null,
                            React.createElement(AnimatedNumbers, { animateToNumber: 1704142, configs: [
                                    { mass: 1, tension: 220, friction: 100 },
                                    { mass: 1, tension: 180, friction: 130 },
                                    { mass: 1, tension: 280, friction: 90 },
                                    { mass: 1, tension: 180, friction: 135 },
                                    { mass: 1, tension: 260, friction: 100 },
                                    { mass: 1, tension: 210, friction: 180 },
                                ], includeComma: true, locale: "en-US" }),
                            ' ',
                            "\uBA85")))),
            React.createElement(BottomGradientSection, { className: "fade-out", style: {
                    background: 'linear-gradient(0deg, rgba(217, 217, 217, 0) 0%, #000000 63.6%)',
                } }))));
};
export default MainCompanyGrowth;
